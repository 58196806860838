import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { Header } from 'components/header'
import Main from 'components/main'
import Footer from 'components/footer'
import { Solid, Gradient } from 'components/background'
import ProjectHeader from 'components/projectHeader'
import ImageBlock from 'components/imageBlock'
import TextBlock from 'components/textBlock'
import ThreeColumn from 'components/threeColumn'
import ProjectFooter from 'components/projectFooter'
import { graphql } from 'gatsby'

const Healthbox = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.healthboxJson.metaTitle}</title>
    </Helmet>
    <Header />
    <Main>
      <article>
        <ProjectHeader
          heading={data.healthboxJson.heading}
          subheading={data.healthboxJson.subheading}
          disciplines={data.healthboxJson.disciplines}
        />
        <ImageBlock src={data.healthboxJson.projectImages.imageOne} alt={data.healthboxJson.metaTitle} />
        <TextBlock copy={data.healthboxJson.copy} />
        <Gradient variant="to-t">
          <ImageBlock src={data.healthboxJson.projectImages.imageTwo} alt={data.healthboxJson.metaTitle} />
        </Gradient>
        <Solid>
          <ThreeColumn>
            <ImageBlock
              src={data.healthboxJson.projectImages.imageOneMobile}
              alt={data.healthboxJson.metaTitle}
              noPadding={true}
            />
            <ImageBlock
              src={data.healthboxJson.projectImages.imageTwoMobile}
              alt={data.healthboxJson.metaTitle}
              noPadding={true}
            />
            <ImageBlock
              src={data.healthboxJson.projectImages.imageThreeMobile}
              alt={data.healthboxJson.metaTitle}
              noPadding={true}
            />
          </ThreeColumn>
          <ImageBlock src={data.healthboxJson.projectImages.imageOneBrand} alt={data.healthboxJson.metaTitle} />
          <ImageBlock src={data.healthboxJson.projectImages.imageTwoBrand} alt={data.healthboxJson.metaTitle} />
          <ImageBlock src={data.healthboxJson.projectImages.imageThreeBrand} alt={data.healthboxJson.metaTitle} />
          <ImageBlock src={data.healthboxJson.projectImages.imageFourBrand} alt={data.healthboxJson.metaTitle} />
          <ImageBlock src={data.healthboxJson.projectImages.imageFiveBrand} alt={data.healthboxJson.metaTitle} />
          <ImageBlock src={data.healthboxJson.projectImages.imageSixBrand} alt={data.healthboxJson.metaTitle} />
          <ImageBlock src={data.healthboxJson.projectImages.imageSevenBrand} alt={data.healthboxJson.metaTitle} />
          <ImageBlock src={data.healthboxJson.projectImages.imageEightBrand} alt={data.healthboxJson.metaTitle} />

          <ImageBlock src={data.healthboxJson.projectImages.imageThree} alt={data.healthboxJson.metaTitle} />
        </Solid>
        <Gradient variant="to-b">
          <ThreeColumn>
            <ImageBlock
              src={data.healthboxJson.projectImages.imageFourMobile}
              alt={data.healthboxJson.metaTitle}
              noPadding={true}
            />
            <ImageBlock
              src={data.healthboxJson.projectImages.imageFiveMobile}
              alt={data.healthboxJson.metaTitle}
              noPadding={true}
            />
            <ImageBlock
              src={data.healthboxJson.projectImages.imageSixMobile}
              alt={data.healthboxJson.metaTitle}
              noPadding={true}
            />
          </ThreeColumn>
        </Gradient>
      </article>
      <ProjectFooter link={data.healthboxJson.nextLink} />
    </Main>
    <Footer />
  </Layout>
)

Healthbox.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default Healthbox

export const query = graphql`
  query HealthboxQuery {
    healthboxJson {
      metaTitle
      metaDescription
      heading
      subheading
      disciplines
      copy
      nextLink
      projectImages {
        imageOne {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwo {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThree {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageOneMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwoMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThreeMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFourMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFiveMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSixMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageOneBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwoBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThreeBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFourBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFiveBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSixBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSevenBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageEightBrand {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`
