import React from 'react'
import PropTypes from 'prop-types'
import { BackgroundColour, BackgroundGradient } from './background.css'

export const Solid = ({ children }) => <BackgroundColour>{children}</BackgroundColour>

export const Gradient = ({ children, variant }) => <BackgroundGradient variant={variant}>{children}</BackgroundGradient>

Gradient.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}
