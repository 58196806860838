import styled from 'styled-components'

export const BackgroundColour = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #eee;
`

export const BackgroundGradient = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background: ${({ variant }) =>
    (variant === 'to-b' && 'linear-gradient(to bottom, rgba(238, 238, 238, 1) 0%, rgba(247, 247, 247, 1) 100%)') ||
    (variant === 'to-t' && 'linear-gradient(to top, rgba(238, 238, 238, 1) 0%, rgba(247, 247, 247, 1) 100%)') ||
    'linear-gradient(to bottom, rgba(238, 238, 238, 1) 0%, rgba(247, 247, 247, 1) 100%)'};
`
